*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}
#about1{
    height: 62vh;
    width: 100%;
    background-color: green;
    background-image: url("/public/Assets/kmkimg12.jpg");
}

#about2{
    height: 100vh;
    /* margin-top: 100px; */
}
.aboutus{
    text-align: center;
    color: white;
    margin-top: 12px;
    height: 70px;
    width: 100%;
    background-color: green;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
}
.ourvm{
    color:green;
    
}
.vision{
    height: 90vh;
    padding-top: 13px;
}
.wlcimg{
  height: 90vh;
  width: 95%;
}

/* Mobile Styles */
@media (max-width: 768px) {
    #about1 {
      height: 50vh; /* Adjust height for smaller screens */
      background-size: cover; /* Ensure background image scales */
      background-position: center; /* Center background image */
    }
  
    #about2 {
      height: auto; /* Adjust height for smaller screens */
      margin-top: 0; /* Reset margin-top for smaller screens */
    }
  
    .aboutus {
      height: 60px; /* Reduce height for mobile */
      margin-top: 8px; /* Reduce top margin */
    }
  
    .vision {
      height: 70vh; /* Adjust height for smaller screens */
      padding-top: 10px; /* Reduce top padding */
    }
    .wlcimg{
      height: 60vh;
      width: 95%;
    }
  }
  
  @media (max-width: 480px) {
    #about1 {
      height: 40vh; /* Further reduce height for very small screens */
    }
  
    .aboutus {
      height: 50px; /* Further reduce height */
      margin-top: 5px; /* Further reduce top margin */
    }
  
    .vision {
      height: 60vh; /* Further adjust height */
      padding-top: 8px; /* Further reduce top padding */
    }
    .wlcimg{
      height: 55vh;
      width: 95%;
    }
  }
  


#about3{
    height: 103vh;
    width: 100%;
margin-top: 30px;
}
.we{
  height: 100px;
  /* text-align: center; */
  color: green;
  display: flex;
  justify-content: center;
  align-items: center;

}
.sustainability{
    height: 250px;
    background-color: white;
    border-radius: 8px;
    padding: 10px;
    text-align: center;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    
}
.sustainability>p{
    margin-top: 15px;
    /* padding: 7px; */
}
.sustainability:hover{
    background-color: green;
    /* transform: scale(1.1); 
  transition: transform 0.3s ease;  */
  color: white;
}


/* Mobile Styles */
@media (max-width: 768px) {
    #about3 {
      height: auto; /* Adjust height for smaller screens */
      margin-top: 20px; /* Reduce margin-top */
    }
  
    .we {
      height: 100px; /* Reduce height for mobile devices */
    }
  
    .sustainability {
      height: 200px; /* Reduce height for mobile devices */
      padding: 15px; /* Increase padding for better spacing */
    }
  
    .sustainability > p {
      margin-top: 10px; /* Adjust margin for better spacing */
    }
  }
  
  @media (max-width: 480px) {
    #about3 {
      height: auto; /* Ensure height adapts to content */
      margin-top: 135px; /* Further reduce margin-top */
    }
  
    .we {
        height: 70px;
        margin-top: 725px;

    }
  
    .sustainability {
      height: 225px; /* Further reduce height for very small screens */
      padding: 7px; /* Adjust padding for very small screens */
    }
  
    .sustainability > p {
      margin-top: 8px; /* Adjust margin for very small screens */
    }
    .wastagecoll{
      padding-right: 195px;
      padding-top: 10px;
    }
  }
  