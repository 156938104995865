*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

#galary{
    height:45vh;
    width: 100%;
}
#galary>.homegalary{
    height: 75%;
    width: 100%;
    background-image: url("/public/Assets/kmkimg2.jpg");
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
}
#galary>.homegalary>h1{
  width: 30%;
  position: relative;
  top: 145px;
  text-align: center;
  color: white;
}
#galary>.namegalary{
  height:30% ;
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  text-align: center;
  color: green
}
#galary>.namegalary>a{
  height: 48px;
  width: 25%;
  padding: 0px;
  font-size: 25px;
  font-weight: bold;
  color: white;
  background-color: green;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
.imgcontroll{
  height: 300px;
    width: 100%;
    overflow: hidden;
    border: 2px solid green;
    margin: 8px;
    border: 2px solid rgb(245, 248, 245); /* Light gray border around the image */
  border-radius: 10px; /* Rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Adds a shadow effect */
  padding: 5px; /* Adds space between the border and the image */
  background-color: rgb(248, 252, 248); /* Background color of the card */
  display: block; /* Ensures the image is displayed as a block element */
  width: 100%; /* Makes the image responsive */
  max-width: 400px; /* Optional: limits the maximum width */
 
}
.imgcontroll:hover{
  transform: scale(1.1); 
  transition: transform 0.3s ease;
}
@media (max-width: 480px) {
  #galary > .homegalary > h1 {
      width: 80%;
      top: 155px;
      left: 25px;
  }
  #galary > .namegalary{
    display: flex;
    /* flex-direction: column; */
  }
  #galary > .namegalary > a {
      width: 30%; /* Adjusted width for better responsiveness */
      font-size: 16px; /* Adjusted font size for better readability */
  }
}