*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}
.whatsapp{
    position: fixed;
    bottom: 10px;
    right: 10px;
    z-index: 9999;
    padding: 10px;
    border-radius: 50px;
    background: white;
}