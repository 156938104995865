

.footer {
  background-color:darkgreen;
  color: #fff;
  padding: 40px 0;
}

.footer h3 {
  color: #fff;
  margin-left:29px;

}

.footer p {
  color: #ccc;
}

.footer ul {
  list-style-type: none;
}

.footer ul li {
  margin-bottom: 10px;
}

.footer ul li a {
  color: #ccc;
  text-decoration: none;
}

.footer ul li a:hover {
  color: #fff;
  text-decoration: underline;
}

@media (max-width: 768px) {
  .footer {
    padding: 100px 0;
  }
  
  .footer .container {
    flex-direction: column;
    text-align: center;
  }
}

.secondphone{
  padding-left: 56px;
}